import React, { FunctionComponent } from 'react';
import { FilterConfig } from '../FilterComponent';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DateRangeValue } from '../../filters/DateFilter';

export interface DateRangePopoverProps {
    anchorDateRangeEl: HTMLDivElement | null,
    onClose: (
        event: {},
        reason: ('backdropClick' | 'escapeKeyDown'),
    ) => void,
    label: string,
    onClick: () => void,
    item: FilterConfig,
    onApply: (value: any) => void,
    Component: FunctionComponent<{
        value?: DateRangeValue;
        onClose?: () => void;
        onReset?: () => void;
        onApply?: (value: DateRangeValue) => void;
    }>;
}

const DateRangePopover: FunctionComponent<DateRangePopoverProps> = props => {
    return <Popover
        disableScrollLock
        open={ !!props.anchorDateRangeEl }
        anchorEl={ props.anchorDateRangeEl }
        onClose={ props.onClose }
        transformOrigin={ {
            vertical: 'top',
            horizontal: 'center',
        } }
        anchorOrigin={ {
            vertical: 'bottom',
            horizontal: 'center',
        } }
    >
        <Box padding={ '16px 22px' }>
            <Box
                display={ 'flex' }
                justifyContent={ 'space-between' }
                paddingBottom={ '5px' }
            >
                <Typography fontSize={ '20px' }>
                    { props.label }
                </Typography>
                <IconButton
                    onClick={ props.onClick }
                >
                    <CloseIcon/>
                </IconButton>
            </Box>
            <props.Component
                value={ props.item.value }
                onApply={ props.onApply }
                onReset={ props.item.remove }
                onClose={ props.onClick }
            />
        </Box>
    </Popover>
};

export default DateRangePopover;
