/**
 * @generated SignedSource<<52e3b29afabbce81ce0cd8d255953efc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderDirection = "Asc" | "Desc" | "%future added value";
export type ConsumerListFilter = {
  accountableLoanCategories?: AccountableLoanCategoryFilter | null | undefined;
  active?: boolean | null | undefined;
  consumerFeatures?: ConsumerFeatureListFilter | null | undefined;
  consumerIntegrations?: ConsumerIntegrationListFilter | null | undefined;
  createdAt?: string | null | undefined;
  createdAtRange?: DateRange | null | undefined;
  features?: FeatureListFilter | null | undefined;
  id?: ReadonlyArray<string> | null | undefined;
  integrations?: IntegrationListFilter | null | undefined;
  name?: string | null | undefined;
  optimisticPaidOff?: boolean | null | undefined;
  requestLimit?: string | null | undefined;
  updatedAt?: string | null | undefined;
  updatedAtRange?: DateRange | null | undefined;
};
export type IntegrationListFilter = {
  id?: ReadonlyArray<string> | null | undefined;
  name?: string | null | undefined;
};
export type FeatureListFilter = {
  id?: ReadonlyArray<string> | null | undefined;
  name?: string | null | undefined;
};
export type ConsumerIntegrationListFilter = {
  id?: ReadonlyArray<string> | null | undefined;
  integration?: IntegrationListFilter | null | undefined;
  integrationId?: ReadonlyArray<string> | null | undefined;
};
export type ConsumerFeatureListFilter = {
  feature?: FeatureListFilter | null | undefined;
  featureId?: ReadonlyArray<string> | null | undefined;
  id?: ReadonlyArray<string> | null | undefined;
};
export type AccountableLoanCategoryFilter = {
  categoryId?: ReadonlyArray<string> | null | undefined;
  enabled?: boolean | null | undefined;
  id?: ReadonlyArray<string> | null | undefined;
  priority: number;
};
export type DateRange = {
  end: any;
  start: any;
};
export type ConsumerOrderArg = {
  active?: PositionedOrderDirection | null | undefined;
  id?: PositionedOrderDirection | null | undefined;
  name?: PositionedOrderDirection | null | undefined;
  optimisticPaidOff?: PositionedOrderDirection | null | undefined;
  requestLimit?: PositionedOrderDirection | null | undefined;
};
export type PositionedOrderDirection = {
  direction?: OrderDirection | null | undefined;
  position?: number | null | undefined;
};
export type ConsumerPaginatedListQuery$variables = {
  after?: string | null | undefined;
  filter: ConsumerListFilter;
  first?: number | null | undefined;
  orderBy?: ConsumerOrderArg | null | undefined;
};
export type ConsumerPaginatedListQuery$data = {
  readonly consumers: {
    readonly " $fragmentSpreads": FragmentRefs<"ConsumerPaginatedList_data">;
  };
};
export type ConsumerPaginatedListQuery = {
  response: ConsumerPaginatedListQuery$data;
  variables: ConsumerPaginatedListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConsumerPaginatedListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ConsumerConnection",
        "kind": "LinkedField",
        "name": "consumers",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ConsumerPaginatedList_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ConsumerPaginatedListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ConsumerConnection",
        "kind": "LinkedField",
        "name": "consumers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "total",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ConsumerEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Consumer",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "72864f83d986e3d56a278638039d678d",
    "id": null,
    "metadata": {},
    "name": "ConsumerPaginatedListQuery",
    "operationKind": "query",
    "text": "query ConsumerPaginatedListQuery(\n  $filter: ConsumerListFilter!\n  $orderBy: ConsumerOrderArg\n  $first: Int\n  $after: String\n) {\n  consumers(filter: $filter, orderBy: $orderBy, first: $first, after: $after) {\n    ...ConsumerPaginatedList_data\n  }\n}\n\nfragment ConsumerPaginatedList_data on ConsumerConnection {\n  pageInfo {\n    startCursor\n    endCursor\n    total\n  }\n  edges {\n    node {\n      id\n      name\n      description\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "773bd3ad4813f0d3592181816d4f2b5c";

export default node;
