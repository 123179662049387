import { isDark } from '../../common/theme';
import Box from '@mui/material/Box';
import React, { FC, PropsWithChildren } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/material';
import { toSxProps } from '../../common/helpers';

type BackgroundColor = 'primary' | 'secondary';
type BackgroundSize = 'medium' | 'large';

export interface BackgroundProps {
    size?: BackgroundSize;
    color?: BackgroundColor;
    sx?: SxProps<Theme>;
}

const Background: FC<PropsWithChildren<BackgroundProps>> = props => {
    const theme = useTheme<Theme>();
    const imageColor = (
        props.color === 'secondary'
            ? isDark(theme)
                ? (theme.palette as any).secondary['900']
                : (theme.palette as any).secondary['A700']
            : isDark(theme)
                ? theme.palette.background.default
                : (theme.palette as any).primary['A100']
    ).replace('#', '');

    return <Box
        sx={ t => ({
            backgroundColor: props.color === 'secondary'
                ? t.palette.background.default
                : isDark(t)
                    ? t.palette.primary.dark
                    : t.palette.primary.main,
            width: '100%',
            height: '100%',
            zIndex: 1,
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='732' height='761' viewBox='0 0 732 761' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M100.663 335.833L-13.7572 307.706L152.838 141.111L100.663 335.833ZM-29.2402 378.598L81.8842 405.915L29.9818 599.618L-29.2402 378.598ZM435.461 246.051L292.972 103.562L520.453 164.515L435.461 246.051ZM486.764 297.354L628.622 439.212L569.376 218.103L486.764 297.354ZM616.918 115.266L731.207 541.797L418.964 854.04L-7.5669 739.751L-121.856 313.22L190.387 0.977151L616.918 115.266ZM395.976 631.622L591.074 579.346L426.158 744.262L395.976 631.622ZM325.909 650.396L132.567 702.202L355.82 762.023L325.909 650.396ZM591.074 504.249L383.959 559.745L439.455 352.63L591.074 504.249ZM227.936 141.111L379.55 292.725L172.441 348.22L227.936 141.111ZM302.12 581.673L150.511 430.064L95.0179 637.166L302.12 581.673ZM324.482 501.45L230.73 407.699L358.797 373.383L324.482 501.45Z' fill='%23${ imageColor }'/%3E%3C/svg%3E"), url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='261' height='262' viewBox='0 0 261 262' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M78.7804 78.2355L38.3658 68.3007L97.2095 9.45695L78.7804 78.2355ZM32.8971 93.3406L72.1476 102.989L53.815 171.407L32.8971 93.3406ZM197.035 46.5231L146.706 -3.80572L227.056 17.7238L197.035 46.5231ZM215.156 64.6442L265.262 114.75L244.336 36.6514L215.156 64.6442ZM261.129 0.328237L301.497 150.985L191.209 261.273L40.5524 220.904L0.18412 70.2481L110.472 -40.04L261.129 0.328237ZM183.089 182.712L252 164.247L193.749 222.498L183.089 182.712ZM158.34 189.343L90.0493 207.642L168.905 228.771L158.34 189.343ZM252 137.722L178.844 157.324L198.446 84.1683L252 137.722ZM123.735 9.45695L177.287 63.009L104.133 82.6105L123.735 9.45695ZM149.938 165.069L96.3874 111.519L76.7866 184.67L149.938 165.069ZM124.722 103.619L169.957 91.4985L157.836 136.733L124.722 103.619Z' fill='%23${ imageColor }'/%3E%3C/svg%3E")`,
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'local',
            backgroundPosition: props.size === 'large'
                ? '-300px 200px, 105% -5%'
                : '0% 100%, 100% 0%'
            ,
            backgroundSize: props.size === 'large'
                ? '100%, 28%'
                : '41%, 19%'
            ,
            color: t.palette.common.white,
            ...(props.sx ? toSxProps(props.sx as any, t) as any : {}),
        }) }
    >
        { props.children }
    </Box>
};

export default Background;
