import { FC } from 'react';
import { EditFormProps } from '../data';
import { capitalize, Theme, Tooltip, Typography } from '@mui/material';
import EnablingDragDropList from './EnablingDragDropList';

const styles = {
    title: (theme: Theme) => ({
        color: theme.palette.text.primary,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: '16px',
        fontWeight: 500,
    }),
}

const AccountableLoans: FC<Pick<
    EditFormProps,
    'initial'
    | 'data'
    | 'setData'
    | 'categories'
    | 'readonly'
>> = ({
    data,
    setData,
    readonly,
}) => {
    const categories =
        [...data.accountableLoanCategories || []];

    const handleOrderChange = (newOrderIds: string[]) => {
        const updatedCategories = newOrderIds.map((id, index) => {
          const existing = categories.find(c => c.category!.id === id)!;
          return {
            ...existing,
            priority: index + 1,
          };
        });
        
        setData({
          ...data,
          accountableLoanCategories: updatedCategories,
        });
      };

    return <EnablingDragDropList
        items={categories.map(({ category }) => ({
            id: category!.id,
            title: (
                <Tooltip title={ capitalize(category!.name) }>
                    <Typography sx={ styles.title }>
                        { category!.name }
                    </Typography>
                </Tooltip>
            ),
        }))}
        selectedIds={new Set(
            (data.accountableLoanCategories || [])
                .filter(c => c.enabled)
                .map(c => c.category!.id)
        )}
        setSelectedIds={selectedIds => setData({
            ...data,
            accountableLoanCategories: (() => {
                const categories = [...data.accountableLoanCategories || []];
                for (const id of selectedIds) {
                    if (!categories.find(i => i.category!.id === id)) {
                        categories.push({ id, category: { id } } as any);
                    }
                }
                const updated = categories.map(c => ({
                    ...c,
                    enabled: selectedIds.has(c.category!.id),
                }));
                return updated;
            })()
        })}
        readonly={ readonly }
        onOrderChange={ handleOrderChange }
    />;
};

export default AccountableLoans;
